<template>
  <b-modal
    id="add-feed-modal"
    centered
    size="lg"
    hide-footer
    no-close-on-backdrop
  >
    <template #modal-header>
      <div class="w-100 text-right c-pointer">
        <img
          src="@/assets/images/close.svg"
          alt="close"
          @click="onClose()"
          width="14"
          height="14"
        />
      </div>
    </template>

    <div class="add-feed-body">
      <div class="text-center">
        <img
          src="@/assets/images/icons/add-post.svg"
          alt="group"
          width="40"
          height="40"
        />
        <h5>{{ $t("discussions.post.add") }}</h5>
      </div>
      <!-- reference URL ( https://vee-validate.logaretm.com/v2/guide/components/validation-observer.html#rendering ) -->
      <ValidationObserver v-slot="{ invalid }" name="add-team-observer">
        <form @submit.prevent="onAddPost">
          <ValidationProvider
            name="title"
            rules="required|max:255"
            v-slot="{ errors }"
          >
            <div class="form-group">
              <Input
                :label="`${$t('teams.team.create.title.label')} *`"
                :placeholder="$t('discussions.new_post.title.label')"
                v-model="addPost.title"
                :showError="errors.length > 0 && errors[0].includes('title')"
                :errorMessage="errors[0]"
              />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="text"
            v-slot="{ errors }"
            rules="required|max:300"
          >
            <div class="form-group">
              <label>{{ $t("teams.team.create.description.label") }}*</label>
              <Textarea
                :placeholder="$t('discussions.new_post.text.placeholder')"
                rows="3"
                v-model="addPost.body"
                :limit="300"
                hasLimit
                postOb
              ></Textarea>
              <span class="error mb-2">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <div class="justify-content-end d-flex">
            <Button
              :disabled="invalid"
              class="btn-primary mt-2 text-uppercase"
              type="submit"
              :pill="true"
            >
              {{ $t("discussions.form.navigation.post") }}
            </Button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>
<script>
import Button from "@/components/WrapperComponents/Button.vue";
import Textarea from "@/components/WrapperComponents/Textarea.vue";
export default {
  props: { topicId: String },
  components: { Button, Textarea },
  data() {
    return {
      addPost: {
        title: "",
        body: ""
      }
    };
  },
  methods: {
    init() {
      this.addPost = {
        title: "",
        body: ""
      };
    },
    onAddPost() {
      let obj = {
        title: this.addPost.title,
        body: this.addPost.body,
        topic_id: this.topicId,
        course_id: this.$route.params.id,
        anonymous: false
      };
      this.$store.dispatch("addPost", obj).then(() => {
        this.init();
        this.$emit("postadded");
        this.$bvModal.hide("add-feed-modal");
      });
    },
    onClose() {
      this.init();
      this.$bvModal.hide("add-feed-modal");
    }
  }
};
</script>
<style lang="scss">
#add-feed-modal {
  .modal-body {
    padding: 1rem 24px;
    .add-feed-body {
      h5 {
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        color: #0057e0;
        padding: 16px 0;
      }
    }
    .form-group {
      label {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: rgba(0, 0, 0, 0.6);
        font-weight: normal;
      }
    }
  }
}
</style>
